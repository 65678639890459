<template>
  <div class="baidu">
      <Top-banner :img='img' :title='title' :text='text'  :name='name'></Top-banner> 
      <public-block :title='publicTitle' :text='publicText' :btn='btn' :img='publicImg' :float1='"left"'  :float='"right"'></public-block>
      <public-block :title='publicTitle2' :text='publicText2' :btn='btn' :img='publicImg2' :float='"left"' :float1='"right"' :bgColor='"rgb(246, 248, 251)"'></public-block>
      <public-block :title='publicTitle3' :text='publicText3' :btn='btn' :img='publicImg3' :float1='"left"'  :float='"right"'></public-block>
      <public-block :title='publicTitle4' :text='publicText4' :btn='btn' :img='publicImg4'  :float='"left"' :float1='"right"' :bgColor='"rgb(246, 248, 251)"'></public-block>
      <!-- 选择百度推广的理由 -->
      <div class='container1080'>
        <div class='titleThis'>选择百度推广的理由</div>
        <div class='baidu-block'>
          <div class='baidu-smallBlock' v-for='(item,index) in lastList' :key="index">
              <img :src="item.img" alt="" class='block-img'>
              <div class='right-block'>
                <div class='block-title'>{{item.title}}</div>
                <div class='block-text'>{{item.text}}</div>
              </div>
          </div>
        </div>
      </div>
    <my-model ref='myModel'></my-model>

  </div>
</template>

<script>
import topBanner from '../components/topBanner';
import publicBlock from '../components/PublicBlock';
import myModel from '../components/MyModel';



export default {
 name: 'Google',
 components:{
   TopBanner:topBanner,
  publicBlock:publicBlock,
   MyModel:myModel
 },
  data() {
    return {
     img:require('../static/images/baidu-bg.jpg'),
     title:'全球第一大搜索引擎',
     text:'拥有众多网盟，只需一个账户您的广告就可以投放到全球，谷歌多样化广告业务解决你的出海问题',
     name:'百度搜索',
     publicTitle:'什么是搜索推广',
     publicText:"百度搜索推广，运用百度独有的搜索大数据积淀与AI能力，理解用户外貌更理解用户大脑。在搜索结果显著的位置展示商家的推广信息，只有客户点击广告后才需付费。投放广告 Network和Messenger等多个平台可提供多种广告类型。",
     btn:'投放广告',
     publicImg:require('../static/images/baidu-public1.png'),
     publicTitle2:'百度聚屏',
     publicText2:'百度聚屏通过多类屏幕，触达消费者多场景生活时刻，实现线上线下广告整合和精准程序化投放，依托百度大数据及AI优势，打造全新的品牌推广体验。',
     publicImg2:require('../static/images/baidu-public2.png'),
     publicTitle3:'信息流广告',
     publicText3:'搜索+兴趣双引擎，精准捕捉用户意图，百度信息流生态系统，连接人与内容以及重点城市高商业价值人群广泛覆盖。',
     publicImg3:require('../static/images/baidu-public3.png'),
    publicTitle4:'开屏广告',
     publicText4:'百度开屏整合百度优质品牌广告流量，以APP开屏广告的样式进行品牌强势曝光。',
     publicImg4:require('../static/images/baidu-public4.png'),
     lastList:[
       {title:"覆盖广泛",text:'全球最大的中文搜索引擎，覆盖95%的中国网民，每天有超过2亿次搜索。',img:require('../static/images/baidu-block1.png')},
       {title:"不限关键词",text:'企业可以同时推广多个关键词，数量不限，让企业每一种产品和服务都能被潜在客户找到。',img:require('../static/images/baidu-block2.png')},
       {title:"效果付费",text:'按照给企业带来的潜在客户访问计费，没有客户访问不计费，灵活控制推广资金投入。',img:require('../static/images/baidu-block3.png')},
       {title:"针对性强",text:'对企业产品真正感兴趣的潜在客户通过“产品关键词”直接访问到企业网站相关页面。',img:require('../static/images/baidu-block4.png')},
       {title:"展示靠前",text:'企业的广告被投放在百度搜索页面显著的位置，潜在客户第一眼能看到您的广告信息。',img:require('../static/images/baidu-block5.png')},
     
     ]
    };
  },
  methods:{

  }
};
</script>

<style>
 .container1080{
  width: 1080px;
  margin: auto;
  padding:80px 0;
}
.titleThis{
    color: rgb(2, 27, 78);
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 80px;
}

.baidu-block{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
 .baidu-smallBlock{
   width: 50%;
   text-align: left;
    padding: 20px 40px
}
.block-img{
    width: 65px;
    margin: auto;
    display: inline-block;
    margin-right: 20px;
    vertical-align:top!important;
}
.right-block{
  display: inline-block;
  width: 320px;
}
.block-title{
      margin-bottom: 10px;
    font-size: 18px;
    color: rgb(30, 30, 30);
}
.block-text{
  color: rgb(141, 140, 140);
      font-size: 12px;
}
</style>