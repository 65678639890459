<template>
      <div>
        <div class='container1080'>
          <div class='titleThis'>{{title}} </div>
          <div class='adv-left'>
            <div class='adv-title'>四大优势</div>
            <div class='adv-block'>
              <div class='adv-smallBlock'  v-for='(item,index) in advList' :key='index'>
                <img :src="item.img" alt="" class='adv-icon'>
                <div class='adv-title2'>{{item.title}}</div>
                <div class='adv-text'>{{item.text}}</div>
              </div>
              <div class='adv-btn' @click='showModel'>投放广告</div>
            </div>
        </div>
        <img :src="advImg" alt="" class='adv-right'>
      </div>
      </div>
    
</template>

<script>
import eventVue from '../eventBus.js'

export default {
 name: 'Advance',
 components:{
   
 },
  data() {
    return {
     active:0
    };
  },
  props: {
   title: String,
   advImg:String,
   advList:Array,
   
  },
      methods:{
      showModel() {
      eventVue.$emit('changeModel',true)
    },
  }

}
</script>

<style>
 .container1080{
  width: 1080px;
  margin: auto;
  padding:80px 0;
}
.titleThis{
    color: rgb(2, 27, 78);
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 80px;
}

.adv-left{
  width: 480px;
    padding: 50px;
    margin: 20px 0 40px;
    display: inline-block;
    margin-left: -50px;
    position: relative;
    background: rgb(249, 250, 251);
}
.adv-title{
    color: rgb(50, 50, 50);
    font-size: 24px;
    margin-top: 30px;
    text-align: left;
    
}
.adv-block{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin:40px 0 ;
}
.adv-smallBlock{
  width: 50%;

}
.adv-icon{
   width: 40px;
    margin-bottom: 30px;
    display: block;
}
.adv-title2{
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    margin: 0 0 15px;
}
.adv-text{
  font-size: 14px;
    text-align: left;
}
.adv-btn{
  margin-top: 60px;
    background: rgb(56, 120, 255);
    padding: 5px 25px;
    border: none;
    font-size: 14px;
    line-height: 24px;
    outline: none;
    cursor: pointer;
    display: inline-block;
    color: #fff;
}
.adv-right{
  display: inline-block;
    width: 430px;
    margin-left: 80px;
    margin-top: 80px;
}
</style>