<template>
  <div class="facebook">
      <Top-banner :img='img' :title='title' :text='text' :icon='icon' :name='name'></Top-banner> 
      <public-block :title='publicTitle' :text='publicText' :btn='btn' :img='publicImg' :float1='"left"' :float='"right"'></public-block>
      <!-- 精确定位您的目标客户 -->
     <four-card :title='cardTitle' :blockList1='blockList1' :bgColor='"rgb(242, 246, 250)"' :titleColor='"rgb(2, 27, 78)"'></four-card>
      <!-- 蓝色条 -->
      <div class='blueBlock'>
        <div class='container1080'>
          <div class='block1'>
            <div class='blockBlue' v-for='(item,index) in blockList2' :key='index'>
            <img :src="item.img" alt="" class='imgBlue'>
            <div class='titleBlue'>{{item.title}}</div>
            <div class='textBlue'>{{item.text}}</div>
          </div>
          </div>
        </div>
      </div>
      <!-- tab切换 -->
      <my-tab :title='tabTitle' :tabList='tabList'></my-tab>
      <introduction :img='introductionImg' :title='introductionTitle' :title2='introductionTitle2' :title3='introductionTitle3' :btn='introductionBtn' :text='introductionText'></introduction>
  <!-- Facebook助您赢销海外 -->
      <div class='container1080'>
        <div class='titleThis'>Facebook助您赢销海外 </div>
        <div class='lastBlock'>
          <div class='lastSmallBlock' v-for='(item,index) in lastList' :key='index'>
            <img :src="item.img" alt="" style='width:42px;height:42px'>
            <div class='lastTitle'>{{item.title}}</div>
            <div class='lastText'>{{item.text}}</div>
          </div>
        </div>
      </div>
    <my-model ref='myModel'></my-model>
  
  </div>
</template>

<script>
import topBanner from '../components/topBanner';
import publicBlock from '../components/PublicBlock';
import myTab from '../components/MyTab';
import introduction from '../components/Introduction';
import fourCard from '../components/FourCard';
import myModel from '../components/MyModel';





export default {
 name: 'Facebook',
 components:{
   TopBanner:topBanner,
   publicBlock:publicBlock,
   MyTab:myTab,
   Introduction:introduction,
   FourCard:fourCard,
   MyModel:myModel
 },
  data() {
    return {
     img:require('../static/images/facebookBanner.jpg'),
     title:'妖星助您实现商业目标',
     text:'整合媒体资源 精准广告投放',
     icon:require('../static/images/facebookIcon.png'),
     name:'Facebook',
     publicTitle:'跨多个社交平台',
     publicText:"Facebook是全球最大的互动社区，月活跃用户达到20亿以上。其系列应用和服务跨Facebook、Instagram、Audience Network和Messenger等多个平台可提供多种广告类型。",
     btn:'了解更多',
     publicImg:require('../static/images/facebookBlock1.png'),
     cardTitle:'精确定位您的目标客户',
     blockList1:[
       {title:'多种格式',text:'支持图片、视频、GIF等广告创意',img:require('../static/images/face-block1.png')},
       {title:'支持动态广告',text:'对不同用户展示不同的广告创意',img:require('../static/images/face-block2.png')},
       {title:'深度数据支撑',text:'CPM、CTR、CPC多种深度数据支撑',img:require('../static/images/face-block3.png')},
       {title:'实时数据监控',text:'pixel、SDK实时监控用户行为',img:require('../static/images/face-block4.png')}, 
    ],
    blockList2:[
      {title:'27亿+ ',text:'Facebook月活跃用户',img:require('../static/images/face-blue1.png')},
      {title:'10亿+ ',text:'Instargram月活跃用户',img:require('../static/images/face-blue2.png')},
      {title:'13亿+ ',text:'Messenger月活跃用户',img:require('../static/images/face-blue3.png')},
    ],
    tabTitle:"多种广告形式等你体验",
    tabList:[
      {tab:"轮播",title:' 图片视频轮播广告',text:'通过动静态画面和声效讲述品牌故事。适用于各种照片和视频风格，能让您利用高质量的广告展现品牌及业务。',img:require('../static/images/face-tab1.jpg')},
      {tab:"Messenger ",title:' messenger广告',text:'方便用户发起与商家的对话。这种广告可协助涵盖与现有或者潜在客户建立密切联系，更有互动性',img:require('../static/images/face-tab2.jpg')},
      {tab:"Instagram",title:' Instagram广告',text:'Instagram是现在非常流行的图片分享社区，广告有时间线和快拍两种展现。利用格式点燃用户热情，吸引他们在移动端采取操作',img:require('../static/images/face-tab3.jpg')},
      {tab:"应用",title:'应用app广告 ',text:'可让用户在下载之前在互动式预览中体验应用。您可以利用这种先试后买的体验，吸引更多的优质客户安装应用',img:require('../static/images/face-tab4.png')},
      {tab:"精品栏",title:' 精品栏广告',text:'让用户可以发现，浏览和购买您的产品和服务。用户轻触精品栏广告有即可快速加载的广告体验，详细了解具体商品。  ',img:require('../static/images/face-tab5.png')},
    ],
    introductionTitle:'Facebook',
    introductionTitle2:'Facebook',
    introductionTitle3:'社交平台',
    introductionText:'Facebook平台的月活跃用户人数高达20亿，这让其成为各种规模的商家发展业务的优质平台。您还在犹豫什么呢？不论商家是刚开始接触社交媒体营销，还是希望开辟全新发展道路，在Facebook发布广告都是明智之选。',
    introductionBtn:'投放广告',
    introductionImg:require('../static/images/face-introduction.jpg'),
    lastList:[
      {title:'庞大的用户群',text:'Facebook是世界上最大的社交媒体，在全球范围内达到了24亿用户，全球几乎三分之一的人都使用Facebook。跃用户数高达12亿，积极抢攻亿万流量，助力品牌实现高营收增长',img:require('../static/images/face-last1.png')},
      {title:'强大的流量导入',text:'世界上最活跃的社交平台。每天全世界都有数以亿计的用户流量，卖家可以做推广将客户带到您商店的主页或网站。',img:require('../static/images/face-last2.png')},
      {title:'社交能力强',text:'用户很粘强。用户被标记得更多，类似的用户很容易在组中添加，并且组之间经常会有类似的信息流。信息容易爆发性传播。',img:require('../static/images/face-last3.png')},
      {title:'Facebook时间线模式',text:'自然不突兀的将广告融入网页，让品牌广告营销于无形',img:require('../static/images/face-last4.png')},
      {title:'精准的广告营销',text:'用户数据，个人偏好和购买历史已成为公司最强大的数据库。使用Facebook，您可以更准确地将广告定位到需要它的人。',img:require('../static/images/face-last5.png')},
      {title:'竞争对手很难找到自己的广告',text:'根据不同的人群定位。只有当它符合人群定位的特征时，才能看到广告消息。竞争对手无法找到或复制自己的广告。',img:require('../static/images/face-last6.png')},
    ]

    };
  },
};
</script>

<style>
 .container1080{
  width: 1080px;
  margin: auto;
  padding:80px 0;
}
.titleThis{
    color: rgb(2, 27, 78);
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 80px;
}

.blueBlock{
  background-color: #597ef7;
}
.blockBlue{
  width: 324px;
  text-align: center;
    margin: 30px 0;
    color:#fff;
}
.imgBlue{
  width: 50px;
    margin: auto;
}
.titleBlue{
  font-size: 24px;
    margin-top: 30px;
}
.textBlue{
  font-size: 16px;
    margin-top: 10px;
}
.lastBlock{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.lastSmallBlock{
  width: 30%;
  margin-bottom: 60px;
  text-align: left;
}

.lastTitle{
  color: #021b4e;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 600;
}
.lastText{
  color: #516286;
    font-size: 14px;
    height: 65px;
}

</style>