<template>
      <div :style="{backgroundColor: bgColor}">
        <div class='container1080'>
        <div class='titleThis' :style='{color:titleColor}'>{{title}} </div>
        <div class='textThis' v-show='text'>{{text}}</div>
        <div class='block1'>
          <div class='blockSmall' v-for='(item,index) in blockList1' :key='index' :style='{border:border}'>
            <img :src="item.img" alt="" class='imgSmall'>
            <div class='titleSmall'>{{item.title}}</div>
            <div class='textSmall'>{{item.text}}</div>
          </div>
        </div>
      </div>
      </div>
</template>

<script>
export default {
 name: 'FourCard',
 components:{
   
 },
  data() {
    return {
     active:0
    };
  },
  props: {
   title: String,
   border: String,
   text:String,
   bgColor:String,
   advImg:String,
   titleColor:String,
   blockList1:Array,

  },

}
</script>

<style>
 .container1080{
  width: 1080px;
  margin: auto;
  padding:80px 0;
}
.titleThis{
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 80px;
}
.textThis{
    font-size: 14px;
    margin: 40px auto;
    text-align: center;
    color: rgb(51, 51, 51);
}
.block1{
  width: 100%;
  padding:0 20px;
  display:flex;
  justify-content: space-between;
}
.blockSmall{
    width: 230px;
    height: 320px;
    border-radius: 4px;
    background: #fff;
    text-align: center;
    margin-right: 30px;
}
.imgSmall{
    width: 85px;
    margin: 40px auto;
    display: block;
}
.titleSmall{
  color: #2b2b2c;
    font-size: 15px;
    margin-bottom: 30px;
}
.textSmall{
  text-align: left;
    font-size: 13px;
    color: #727476;
    margin: 0 10px 30px;
}
</style>