<template>
    <div class='TabContainer'>
      <div class='container1050'>
        <div class='TabTitle'>{{title}}</div>
        <template>
          <div>
            <a-tabs :default-active-key="active">
              <a-tab-pane v-for='(item,index) in tabList' :key="index" :tab="item.tab">
                <div class='tabBlock'>
                  <div class='phoneBg'>
                    <img :src="item.img" alt="" class='tabImg'>
                  </div>
                  <div class='tabTextContainer'>
                    <div class='tabRightTitle'>{{item.title}}</div>
                    <div class='tabRightText'>{{item.text}}</div>
                  </div>
                </div>
              </a-tab-pane>
            </a-tabs>
          </div>
        </template>
      </div>
     
    </div>
    
</template>

<script>
export default {
 name: 'MyTab',
 components:{
   
 },
  data() {
    return {
     active:0
    };
  },
  props: {
   title: String,
   tabList:Array,
   
  },

}
</script>

<style>
.TabContainer{
  padding:80px 0!important;
  background: rgb(249, 252, 255);
  text-align: center;
  height: 880px;
  
}
.container1050{
width: 1050px;
  margin: 0 auto;
}
.TabTitle{
    font-size: 24px;
    font-weight: 600;
    color: rgb(30, 30, 30);
    margin-bottom: 20px;
}
.tabBlock{
    z-index: 2;
    text-align: center;
    width: 1050px;
    padding: 15px;
    color: #fff;
    position: relative;
    box-sizing: border-box;
    animation-duration: .5s;
    margin-top: 30px;
}
.tabImg{
  position: absolute;
    border-radius: 14px;
    width: 260px;
    height: 560px;
    top: 16px;
    left: 50px
}
.tabTextContainer{
  float:right;
      position: absolute;
    top: 260px;
    right: 100px;
    text-align: left;
    width: 500px;
}
.tabRightTitle{
  color: #181818;
    font-size: 22px;
    font-weight: 600;
}
.tabRightText{
  width: 500px;
    color: #747171;
    font-size: 14px;
}
.phoneBg{
  width: 350px;
  position: relative;
  height: 610px;
  float: left;
  background-image: url('../static/images/phone-bg.png');
}
</style>