<template>
    <div class='InContainer'>
      <div class='inLeft'>
        <div class='leftBlock'>
          <div style='font-size:50px;font-weight:bold'>{{title}}</div>
          <div class='line' ></div>
          <div style='font-size:35px;font-weight:bold'>{{title2}}</div>
          <div style='font-size:35px;font-weight:bold'>{{title3}}</div>
          <div class='inText'>{{text}}</div>
          <div class='btn' @click='showModel'>{{btn}}</div>
        </div>
        
      </div>
      <img :src="img" alt="" class='inRight'>
     
    </div>
    
</template>

<script>
import eventVue from '../eventBus.js'
export default {
 name: 'Introduction',
 components:{
   
 },
  data() {
    return {
     active:0
    };
  },
  props: {
   title: String,
   title2: String,
   title3: String,
   text: String,
   btn: String,
    img: String,
   
   
  },
    methods:{
      showModel() {
      eventVue.$emit('changeModel',true)
    },
  }

}
</script>

<style>
.InContainer{
  width:100%;
      background: rgb(229, 240, 255);
      height: 900px;
}
.inLeft{
  width: 60%;
  float:left;
  padding-top: 115px;
  color: rgb(83, 123, 190);
  text-align: left;
}
.leftBlock{
  width: 640px;
  float:right;
  padding-right: 80px;
}
.inRight{
  width:38%;
  float: right;
}
.line{
  width: 2px;
    height: 40px;
    margin-bottom: 20px;
    margin-top: 10px;
    background-color:rgb(83, 123, 190);
}
.inText{
  margin: 20px 0;
    font-size: 14px;
    line-height: 30px;
}
 .btn{
    background: rgb(56, 120, 255);
    padding: 10px 35px;
    border: none;
    font-size: 14px;
    line-height: 24px;
    outline: none;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    border-radius: 28px;
    display: inline-block;
    margin-left: 0!important;
}
</style>