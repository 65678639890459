<template>
  <div :style="{backgroundColor: bgColor}">
    <div class='publicContainer' >
     <div class='publicText' :style="{float:float1}">
       <div class='title'>{{title}}</div>
       <div class='text'>{{text}}</div>
       <div class='btn'  @click='showModel'>{{btn}}</div>
     </div>
     <img :src="img" alt="" class='publicImg' :style="{float:float}">
    </div>
  </div>
    
    
</template>

<script>
import eventVue from '../eventBus.js'
export default {
 name: 'PublicBlock',
 components:{
   
 },
  data() {
    return {
     
    };
  },
  props: {
   title: String,
   text:String,
   btn:String,
   bgColor:String,
   img:String,
   float:String,
   float1:String
  },
    methods:{
      showModel() {
      eventVue.$emit('changeModel',true)
    },
  }
};
</script>

<style>
.publicContainer{
  width: 1080px;
  overflow: hidden;
  margin:0px auto;
  padding: 80px 0;
}
.publicText {
  width: 400px;
  text-align: left;
  margin-top: 60px;
}
.publicText .title{
   color: rgb(51, 51, 51);
    font-size: 32px;
    font-weight: 700;
    text-align: left;
}
.publicText .text{
    margin: 15px 0;
    color: rgb(156, 156, 156);
    font-size: 16px;
    line-height: 20px;
}
.publicText .btn{
    background: rgb(56, 120, 255);
    padding: 10px 35px;
    border: none;
    font-size: 14px;
    line-height: 24px;
    outline: none;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    border-radius: 28px;
    display: inline-block;
    margin-left: 0!important;
}
.publicImg{
  display: block;
  width: 500px;
}
</style>